import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  veri: any;
  hidelar: string = "hidden";
  durum: number = 0;
  gizliozellik:string = "hidden";
  gizliozellikt:string = "hidden";
  kargodurumu:string = "yükleniyor..";
  constructor(private modalCtrl: ModalController,
    private navctrl: NavController,
    private profileService: ProfileService,


  ) { }
  async ionViewWillEnter() {

    setTimeout(() => {

    console.log(this.veri);
    console.log(this.durum);



      if (this.veri.kargo_tur != undefined) {
        this.hidelar = "";
        console.log("açıldı");



      } else {
        console.log("gizlendi");
        this.veri.resim_url = "../../../assets/icon/2.svg";
        this.hidelar = "hidden";


        if(this.veri.adi.durum == "cube"){
          console.log("daha alinmamis");
          this.kargodurumu = "Alınmamış" ;
        }else if(this.veri.adi.durum == "bicycle"){
          console.log("yolda");
          this.kargodurumu = "Yolda";

        }else if(this.veri.adi.durum == "checkmark-done"){
          console.log("tamamlandı");
          this.kargodurumu = "Tamamlandı  "
        }

      }




      if (this.durum == 1) {
        this.gizliozellik = "";
        this.gizliozellikt = "hidden";
        this.veri = this.veri.adi;

      } else if (this.durum == 0) {

        this.gizliozellik = "hidden";
        this.gizliozellikt = "";

      }
    }, 500);
  }

  mesajat() {
    this.confirm();



    let navigationExtras: NavigationExtras = {
      queryParams: {
        toid: this.veri.kullanici_id,
        fromid: this.profileService.useridsi,
        data: this.veri,
      }
    };
    this.navctrl.navigateRoot("chat", navigationExtras);
  }
  confirm() {
    return this.modalCtrl.dismiss("a", 'confirm');
  }
}
