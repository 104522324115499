import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    
    pathMatch: 'full'

    
  },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
    ,canActivate:[AuthGuard]
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },

  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'mesajlar',
    loadChildren: () => import('./mesajlar/mesajlar.module').then( m => m.MesajlarPageModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./store/store.module').then( m => m.StorePageModule)
  },
 
  {
    path: 'stores',
    loadChildren: () => import('./stores/stores.module').then( m => m.StoresPageModule)
  },
  {
    path: 'iletisim',
    loadChildren: () => import('./iletisim/iletisim.module').then( m => m.IletisimPageModule)
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then( m => m.CmsPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'urunler',
    loadChildren: () => import('./urunler/urunler.module').then( m => m.UrunlerPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'urundetay',
    loadChildren: () => import('./urundetay/urundetay.module').then( m => m.UrundetayPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'personeldetay',
    loadChildren: () => import('./personeldetay/personeldetay.module').then( m => m.PersoneldetayPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'alimsatim',
    loadChildren: () => import('./alimsatim/alimsatim.module').then( m => m.AlimsatimPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then( m => m.PanelPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'siparis',
    loadChildren: () => import('./siparis/siparis.module').then( m => m.SiparisPageModule)
    ,canActivate:[AuthGuard]

  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'sinema',
    loadChildren: () => import('./sinema/sinema.module').then( m => m.SinemaPageModule)
  },
  {
    path: 'magazalar',
    loadChildren: () => import('./magazalar/magazalar.module').then( m => m.MagazalarPageModule)
  },
  {
    path: 'etkinlikekle',
    loadChildren: () => import('./etkinlikekle/etkinlikekle.module').then( m => m.EtkinlikeklePageModule)
    ,canActivate:[AuthGuard]
  },
  {
    path: 'magazaekle',
    loadChildren: () => import('./magazaekle/magazaekle.module').then( m => m.MagazaeklePageModule)
    ,canActivate:[AuthGuard]
  },
  {
    path: 'etkinlikdetay',
    loadChildren: () => import('./etkinlikdetay/etkinlikdetay.module').then( m => m.EtkinlikdetayPageModule)
  },
  {
    path: 'magazadetay',
    loadChildren: () => import('./magazadetay/magazadetay.module').then( m => m.MagazadetayPageModule)
  },
  {
    path: 'katedit',
    loadChildren: () => import('./katedit/katedit.module').then( m => m.KateditPageModule)
  },

//cvt gidicek url kidi kalkıcak


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules ,
      useHash: true,
    }
      )
    
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// belge girme
//alanlar ve sınıflar
// iş akış şeması
//rich text
//konfigatöre